import React from "react";
import { Helmet } from "react-helmet"
import { Link } from "gatsby";


import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from "../components/Layout";

const ServiceCategory = ({ pageContext }) => {
    const { item } = pageContext;
    const { title, articles } = item;

    const lowercaseTitle = title.toLowerCase();

    const seoTitle = item.seoTitle;
    const seoDescription = item.seoDescription;
    const seoKeywords = item.seoKeywords.join(", ");
    

    return (
        <Layout stickyHeader={false}>
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={seoDescription} />
                <meta name="keywords" content={seoKeywords} />
            </Helmet>
            <div class={`page-in-${lowercaseTitle}`}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 pull-left">
                            <div class="page-in-name">{item.title}</div>
                        </div>
                        <div class="col-lg-6 pull-right">
                            <div class="page-in-bread">
                                <Link to="/">
                                    <strong>Home</strong>
                                </Link> / {title}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-ms-12">
                        <div class="sd-large-btn sd-training-btn marg15">
                            <Link to="../training/">Training</Link>
                        </div>
                        <div class="sd-large-btn sd-analysis-btn marg15">
                            <Link to="../analysis/">Analysis</Link>
                        </div>
                        <div class="sd-large-btn sd-compliance-btn marg15">
                            <Link to="../compliance/">Compliance</Link>
                        </div>
                        <div class="sd-large-btn sd-legal-btn marg15">
                            <Link to="../legal/">Legal</Link>
                        </div>
                    </div>
                </div>

                <div class="row">
                    {!!articles && articles.map((article, i) => {
                        const articleBody = JSON.parse(article.body.body);                        
                        const articleBodyHtml = documentToReactComponents(articleBody, {});

                        return (
                            <div key={i} class="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-ms-12 marg50">
                                <div class="blog-main">
                                    <div class="blog-name">
                                        <Link to={article.urlSlug}>
                                            {article.title}
                                        </Link>
                                    </div>
                                    <br />
                                    <div class="blog-text" id={`article-${i}`}>
                                        {articleBodyHtml[0]}
                                    </div>
                                    <br />
                                    <div class={`sd-medium-btn sd-${lowercaseTitle}-btn`}>
                                        <Link to={item.urlSlug + '/' + article.urlSlug}>
                                            Read more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <br />
                <br />
                <br />
            </div>
        </Layout>
    );
}


export default ServiceCategory;